<template>
  <div class="top-up-method-container p-4 flex items-center justify-center">
    <div class="rounded-lg card w-full overflow-y-auto py-4 sm:px-6 px-4 vs-con-loading__container" :style="`max-width: ${title === 'Bank Transfer Top-up' && step === 1 ? '900px' : '700px' };`" id="top-up-loading">
      <div class="flex items-center justify-between mb-4">
        <p class="font-medium text-primary text-xl">{{$t(title)}}</p>
        <img v-if="title === 'Bank Transfer Top-up' && step === 1" src="@/assets/images/pages/top-up-instapay.svg" alt="Instapay image">
        <p v-else @click="closeModal" class="material-symbols-outlined cursor-pointer text-2xl">close</p>
      </div>
      <div v-if="step === 0" class="grid sm:grid-cols-1 md:w-4/5 m-auto justify-evenly md:gap-8 gap-4 sm:my-8 my-4">
        <div v-for="(method, index) in topUpMethods" :key="index">
          <div @click="chooseMethod(method)" :class="method.selected ? 'selected' : ''" class="radio-card cursor-pointer flex flex-col items-center justify-between p-3">
            <p class="self-end material-symbols-outlined text-2xl text-primary">{{method.selected ? 'radio_button_checked' : 'radio_button_unchecked'}}</p>
            <img :src="method.image" :alt="method.title">
            <p class="text-primary font-medium text-lg">{{$t(method.title)}}</p>
          </div>
        </div>
      </div>

      <!-- Bank Transfer -->
      <div v-if="selectedMethod === 'Bank Transfer'">
        <!-- step 1 -->
        <div v-if="step === 1" class="mb-4 mt-8">
          <p class="text-lg">{{$t('Open the Instapay app and make a transfer with using these details.')}}</p>
          <div class="mt-6 flex flex-wrap justify-between gap-x-6 gap-y-2 border border-l-0 border-r-0 border-t-0 border-solid pb-8 border-grey-light">
            <div>
              <div class="flex items-center gap-2">
                <p class="text-black leading-tight">{{$t('Bank Name') + ':'}}</p>
                <div class="relative flex">
                  <span v-if="copiedItem === 1" class="copy text-xs whitespace-no-wrap">{{ $t('Copied') }}</span>
                  <span @click="copied('Commercial International Bank (CIB)', 1)" class="material-symbols-outlined text-xl select-none cursor-pointer">content_copy</span>
                </div>
              </div>
              <p class="text-primary">{{'Commercial International Bank (CIB)'}}</p>
            </div>
            <div>
              <div class="flex items-center gap-2">
                <p class="text-black leading-tight">{{$t('Account Number (EGP)') + ':'}}</p>
                <div class="relative flex">
                  <span v-if="copiedItem === 2" class="copy text-xs whitespace-no-wrap">{{ $t('Copied') }}</span>
                  <span @click="copied('100049175269', 2)" class="material-symbols-outlined text-xl select-none cursor-pointer">content_copy</span>
                </div>
              </div>
              <p class="text-primary">{{'100049175269'}}</p>
            </div>
            <div>
              <div class="flex items-center gap-2">
                <p class="text-black leading-tight">{{$t('Beneficiary Name') + ':'}}</p>
                <div class="relative flex">
                  <span v-if="copiedItem === 3" class="copy text-xs whitespace-no-wrap">{{ $t('Copied') }}</span>
                  <span @click="copied('ShipBlu Technology', 3)" class="material-symbols-outlined text-xl select-none cursor-pointer">content_copy</span>
                </div>
              </div>
              <p class="text-primary">{{'ShipBlu Technology'}}</p>
            </div>
          </div>
          <div class="flex md:flex-row flex-col sm:gap-8 gap-6 mt-10">
            <div class="md:w-2/3 md:self-center">
              <ul class="font-medium flex gap-1">
                <span class="material-symbols-outlined text-primary text-2xl">check</span>
                <li>{{$t('ShipBlu does not have a payment address on Instapay, please make sure to use the account number for transfers.')}}</li>
              </ul>
              <ul class="font-medium flex gap-1 mt-2">
                <span class="material-symbols-outlined text-primary text-2xl">check</span>
                <li>{{$t('Transfers must be from a bank account under your name.')}}</li>
              </ul>
              <ul class="font-medium flex gap-1 mt-2">
                <span class="material-symbols-outlined text-primary text-2xl">check</span>
                <li>{{$t('Please allow up to 2 business days to process your top-up request with Instapay.')}}</li>
              </ul>
              <div class="mt-8">
                <p class="text-lg text-black mb-1">{{$t('Deposit Date:')}}</p>
                <input type="date" v-validate="'required'" name="date deposited" v-model="deposit.deposit_date" :class="`${deposit.deposit_date ? 'text-primary' : 'text-grey'} deposit-input text-lg font-medium`">
                <p class="text-danger text-sm" v-show="errors.has('date deposited')">{{ errors.first('date deposited') }}</p>

                <p class="mt-4 text-lg text-black mb-1">{{$t('Deposited Amount:')}}</p>
                <input type="text" v-validate="'required|numeric|max:10'" name="amount deposited" v-model="deposit.amount" :placeholder="0 + ' ' + $t('EGP')" class="deposit-input text-primary text-lg font-medium">
                <p class="text-danger text-sm" v-show="errors.has('amount deposited')">{{ errors.first('amount deposited') }}</p>

                <p class="mt-4 text-lg text-black mb-1">{{$t('Transaction Reference Number:')}}</p>
                <input type="text" v-validate="'required'" name="transaction reference number" v-model="deposit.transaction_reference_number" :placeholder="$t('Reference Number')" class="deposit-input text-primary text-lg font-medium">
                <p class="text-danger text-sm" v-show="errors.has('transaction reference number')">{{ errors.first('transaction reference number') }}</p>
              </div>
            </div>
            <div class="md:w-1/3">
              <input name="file" type="file" @change="changeImage" v-validate="'required'" class="hidden" ref="fileInput" accept="image/jpg, image/jpeg, application/pdf">
              <div
                @click="$refs.fileInput.click()"
                class="m-auto h-full min-h-24 w-full flex items-center justify-center cursor-pointer p-2 text-center border rounded-lg border-dashed border-grey text-xl">
                <div v-if="file === '' || Object.keys($refs.fileInput.files).length === 0" class="p-2">
                  <p class="material-symbols-outlined text-primary text-4xl">upload_file</p>
                  <p class="text-sm text-primary font-medium">{{$t('Upload Receipt')}} </p>
                </div>
                <div v-else-if="$refs.fileInput.files[0].name.split('.')[1] === 'pdf'">
                  <p class="material-symbols-outlined text-primary text-4xl">task</p>
                  <p class="text-sm text-primary font-medium">{{$refs.fileInput.files[0].name}}</p>
                </div>
                <div v-else class="flex items-center justify-center">
                  <img class="w-full rounded-lg" :src="file"/>
                </div>
              </div>
              <p class="text-danger text-sm" v-show="errors.has('file')">{{ errors.first('file') }}</p>
            </div>
          </div>
          <div class="mt-8 flex sm:flex-row flex-col gap-3">
            <div @click="closeModal" class="sm:order-1 order-2 sm:w-1/2 text-center border border-solid border-grey text-grey py-2 px-4 rounded-lg cursor-pointer font-semibold text-lg">
              <p>{{$t('Cancel')}}</p>
            </div>
            <div @click="craeteTopUpRequest(2)" class="sm:order-2 order-1 sm:w-1/2 text-center bg-primary text-white py-2 px-4 rounded-lg cursor-pointer font-semibold text-lg">
              <p>{{$t('Confirm Deposit')}}</p>
            </div>
          </div>
        </div>

        <!-- step 2 -->
        <div v-if="step === 2" class="my-8">
          <p class="text-lg font-medium">
            <span>{{$t('Your request to deposit an amount of EGP')}}</span>
            <span class="text-primary">{{' ' + depositedAmount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' '}}</span>
            <span>{{$t('has been received and your account will be updated within 2 Business Days.')}}</span>
          </p>
        </div>
      </div>

      <!-- Debit/Credit Card -->
      <div v-if="selectedMethod === 'Debit/Credit Card'">
        <!-- step 1 -->
        <div v-if="step === 1" class="sm:flex justify-between gap-4 sm:my-4">
          <div class="sm:w-3/5 flex flex-col justify-between">
            <div>
              <p class="text-primary font-medium mb-4">{{$t('How much to top-up with?')}}</p>
              <div class="flex items-center justify-between gap-4 input-container">
                <p class="text-primary text-lg font-medium">{{$t('EGP')}}</p>
                <input @keyup="calculateFees()" v-model="deposit.amount" v-validate="'required|numeric|max:10'" name="amount deposited" type="text" placeholder="0.00" class="border-0 text-primary text-lg font-medium text-right overflow-hidden">
              </div>
              <p class="text-danger text-sm" v-show="errors.has('amount deposited')">{{ errors.first('amount deposited') }}</p>
              <div class="flex items-center justify-between px-4 mt-2 font-medium text-grey-dark">
                <p>{{$t('Processing Fees')}}</p>
                <p class="flex items-center gap-4">
                  <span class="text-sm">{{$t('EGP')}}</span>
                  <span>{{totalFees ? totalFees.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</span>
                </p>
              </div>
            </div>
            <div @click="craeteTopUpRequest(1)" class="flex flex-wrap justify-between gap-x-4 mt-4 items-center bg-primary text-white py-2 px-4 rounded-lg cursor-pointer font-semibold text-lg">
              <p>{{$t('Confirm Deposit')}}</p>
              <p class="flex items-center ml-auto gap-4 overflow-hidden">
                <span>{{$t('EGP')}}</span>
                <span>{{deposit.amount ? Number(deposit.amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</span>
              </p>
            </div>
          </div>
          <div class="text-center">
            <img src="@/assets/images/pages/top-up-card.svg" width="200" alt="">
          </div>
        </div>

        <!-- step 2 -->
        <div v-if="step === 2" style="height: 795px;">
          <iframe width="100%" height="100%" :src="xPayURL" frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18nData from '../../../i18n/i18nData'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import common from '../../../assets/utils/common'

export default {
  data () {
    return {
      step: 0,
      title: 'Top-up Method',
      copiedItem: null,
      selectedMethod: '',
      deposit: {},
      depositedAmount: 0,
      totalFees: '',
      file: '',
      xPayURL: '',
      topUpMethods: [
        {
          id: 2,
          title: 'Bank Transfer',
          image: require('@/assets/images/pages/top-up-instapay.svg'),
          selected: false
        }
      ]
    }
  },
  methods: {
    chooseMethod (method) {
      method.selected = true
      this.deposit.channel = method.id === 1 ? 'card_payment' : method.id === 2 ? 'bank_transfer' : ''
      setTimeout(() => {
        this.step = 1
        this.title = `${method.title} Top-up`
        this.selectedMethod = method.title
      }, 100)
    },
    calculateFees () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: '#top-up-loading',
            scale: 0.45
          })
          const data = {
            'payable_type': 'topup_request',
            'base_amount': Number(this.deposit.amount)
          }
          sendRequest(true, false, this, 'api/v1/payments/processing-fees/', 'post', data, true,
            (response) => {
              this.totalFees = response.data.cc_fees + response.data.vat
              this.$vs.loading.close('#top-up-loading > .con-vs-loading')
            }, (error) => {
              common.notifyResponseError(this, error)
              this.$vs.loading.close('#top-up-loading > .con-vs-loading')
            }
          )
        }
      })
    },
    craeteTopUpRequest (methodID) {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: '#top-up-loading',
            scale: 0.45
          })
          this.deposit = {
            ...this.deposit,
            amount: Number(this.deposit.amount)
          }
          sendRequest(true, false, this, 'api/v1/billing/topup-requests/', 'post', this.deposit, true,
            (response) => {
              this.depositedAmount = response.data.amount
              if (methodID === 2) this.uploadImage(response.data.id)
              else {
                this.createPaymentRequest(response.data.id)
              }
            }, (error) => {
              common.notifyResponseError(this, error)
              this.$vs.loading.close('#top-up-loading > .con-vs-loading')
            }
          )
        }
      })
    },
    createPaymentRequest (topUpRequestID) {
      sendRequest(true, false, this, `api/v1/payments/payment-requests/topup-requests/${topUpRequestID}/`, 'post', {}, true,
        (response) => {
          this.loadxPayURL(response.data.id)
        }, (error) => {
          common.notifyResponseError(this, error)
          this.$vs.loading.close('#top-up-loading > .con-vs-loading')
        }
      )
    },
    loadxPayURL (paymentRequestID) {
      sendRequest(true, false, this, 'api/v1/payments/xpay/pay/', 'post', {'payment_request': paymentRequestID}, true,
        (response) => {
          this.xPayURL = response.data.payment_url
          this.step = 2
          this.$vs.loading.close('#top-up-loading > .con-vs-loading')
        }, () => {
          this.$vs.loading.close('#top-up-loading > .con-vs-loading')
        }
      )
    },
    changeImage () {
      const [file] = this.$refs.fileInput.files
      if (file) {
        this.file = URL.createObjectURL(file)
      }
    },
    uploadImage (topUpRequestID) {
      const formData = new FormData()
      formData.append('receipt_file', this.$refs.fileInput.files[0])
      sendRequest(true, false, this, `api/v1/billing/topup-requests/${topUpRequestID}/file-upload/`, 'post', formData, true,
        () => {
          this.step = 2
          this.$vs.loading.close('#top-up-loading > .con-vs-loading')
        }, () => {
          this.$vs.loading.close('#top-up-loading > .con-vs-loading')
        }
      )
    },
    closeModal () {
      this.$emit('topUpModal', false)
    },
    async copied (data, number) {
      try {
        await navigator.clipboard.writeText(data)
        this.copiedItem = number
        setTimeout(() => {
          this.copiedItem = null
        }, 750)
      } catch ($e) {
        this.$vs.notify({
          color: 'dark',
          title: i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    }
  }
}
</script>

<style lang="scss">
.top-up-method-container {
  position: fixed;
  inset: 0;
  z-index: 99999;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
  background: rgba(255, 254, 254, 0.6);
  .card {
    max-height: calc(100dvh - 28px);
    max-width: 700px;
    box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.25);
    background: rgba(255, 254, 254, 1);
    .copy {
      position: absolute;
      top: -16px;
      left: -10px;
    }
    .radio-card {
      height: 231px;
      box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
      background: rgba(255, 254, 254, 1);
      border-radius: 10px;
      &.selected {
        border: 2px solid rgba(28, 91, 254, 1)
      }
    }
    .input-container {
      box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.25) inset;
      border-radius: 8px;
      padding: 10px 16px;
      input {
        &::placeholder {
          color: #1c5bfe;
        }
      }
    }
    .deposit-input {
      width: 100%;
      height: 35px;
      padding: 6px 10px;
      border: 1px solid #D2D2D2;
      border-radius: 3px;
      &::placeholder {
        color: #b8c2cc;
      }
    }
    ::-webkit-calendar-picker-indicator {
      background-image: url("../../../assets/images/pages/calendar.svg");
      background-position: center;
      background-size: 20px 20px;
      cursor: pointer;
    }
  }
}
</style>